// Set custom font face
@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

// navbar settings
$navbar-padding-x: 1rem;

// Add custom font to default bootstrap font stack
$font-family-sans-serif:
	// Custom font
	"OpenSans",
		// Safari for OS X and iOS (San Francisco)
	-apple-system,
		// Chrome < 56 for OS X (San Francisco)
	BlinkMacSystemFont,
		// Windows
	"Segoe UI",
		// Android
	"Roboto",
		// Basic web fallback
	"Helvetica Neue", Arial, sans-serif,
		// Emoji fonts
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
