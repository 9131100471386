.halflings-user::after {
  content: "\e900";
}
.halflings-cogwheel::after {
  content: "\e901";
}
.halflings-envelope::after {
  content: "\e902";
}
.halflings-camera::after {
  content: "\e903";
}
.halflings-heart::after {
  content: "\e904";
}
.halflings-print::after {
  content: "\e905";
}
.halflings-bin::after {
  content: "\e906";
}
.halflings-music::after {
  content: "\e907";
}
.halflings-heart-empty::after {
  content: "\e908";
}
.halflings-home::after {
  content: "\e909";
}
.halflings-magnet::after {
  content: "\e90a";
}
.halflings-search::after {
  content: "\e90b";
}
.halflings-pencil::after {
  content: "\e90c";
}
.halflings-file::after {
  content: "\e90d";
}
.halflings-picture::after {
  content: "\e90e";
}
.halflings-stats::after {
  content: "\e90f";
}
.halflings-stats-bars::after {
  content: "\e910";
}
.halflings-stats-circle::after {
  content: "\e911";
}
.halflings-user-group::after {
  content: "\e912";
}
.halflings-key::after {
  content: "\e913";
}
.halflings-calendar::after {
  content: "\e914";
}
.halflings-star::after {
  content: "\e915";
}
.halflings-star-empty::after {
  content: "\e916";
}
.halflings-pulse::after {
  content: "\e917";
}
.halflings-eye::after {
  content: "\e918";
}
.halflings-eye-off::after {
  content: "\e919";
}
.halflings-clock::after {
  content: "\e91a";
}
.halflings-scissors::after {
  content: "\e91b";
}
.halflings-copy-duplicate::after {
  content: "\e91c";
}
.halflings-paste::after {
  content: "\e91d";
}
.halflings-compass::after {
  content: "\e91e";
}
.halflings-paperclip::after {
  content: "\e91f";
}
.halflings-power::after {
  content: "\e920";
}
.halflings-tag::after {
  content: "\e921";
}
.halflings-education::after {
  content: "\e922";
}
.halflings-ruler::after {
  content: "\e923";
}
.halflings-gift::after {
  content: "\e924";
}
.halflings-book::after {
  content: "\e925";
}
.halflings-bookmark::after {
  content: "\e926";
}
.halflings-wifi::after {
  content: "\e927";
}
.halflings-headphones::after {
  content: "\e928";
}
.halflings-triangle-alert::after {
  content: "\e929";
}
.halflings-bars::after {
  content: "\e92a";
}
.halflings-retweet::after {
  content: "\e92b";
}
.halflings-refresh::after {
  content: "\e92c";
}
.halflings-roundabout::after {
  content: "\e92d";
}
.halflings-random::after {
  content: "\e92e";
}
.halflings-reload::after {
  content: "\e92f";
}
.halflings-tv::after {
  content: "\e930";
}
.halflings-building::after {
  content: "\e931";
}
.halflings-contrast::after {
  content: "\e932";
}
.halflings-drop::after {
  content: "\e933";
}
.halflings-vector-path::after {
  content: "\e934";
}
.halflings-text::after {
  content: "\e935";
}
.halflings-italic::after {
  content: "\e936";
}
.halflings-bold::after {
  content: "\e937";
}
.halflings-underline::after {
  content: "\e938";
}
.halflings-strikethrough::after {
  content: "\e939";
}
.halflings-square-empty::after {
  content: "\e93a";
}
.halflings-playlist::after {
  content: "\e93b";
}
.halflings-paragraph-left::after {
  content: "\e93c";
}
.halflings-paragraph-center::after {
  content: "\e93d";
}
.halflings-paragraph-right::after {
  content: "\e93e";
}
.halflings-paragraph-justify::after {
  content: "\e93f";
}
.halflings-list::after {
  content: "\e940";
}
.halflings-terminal::after {
  content: "\e941";
}
.halflings-code::after {
  content: "\e942";
}
.halflings-table::after {
  content: "\e943";
}
.halflings-inbox::after {
  content: "\e944";
}
.halflings-hash::after {
  content: "\e945";
}
.halflings-adjust::after {
  content: "\e946";
}
.halflings-database::after {
  content: "\e947";
}
.halflings-folder::after {
  content: "\e948";
}
.halflings-square-edit::after {
  content: "\e949";
}
.halflings-square-new-window::after {
  content: "\e94a";
}
.halflings-square-checkbox::after {
  content: "\e94b";
}
.halflings-square-selected::after {
  content: "\e94c";
}
.halflings-square-indeterminate::after {
  content: "\e94d";
}
.halflings-thumbnails::after {
  content: "\e94e";
}
.halflings-thumbnails-small::after {
  content: "\e94f";
}
.halflings-skip-to-previous::after {
  content: "\e950";
}
.halflings-skip-to-start::after {
  content: "\e951";
}
.halflings-rewind::after {
  content: "\e952";
}
.halflings-pause::after {
  content: "\e953";
}
.halflings-play::after {
  content: "\e954";
}
.halflings-stop::after {
  content: "\e955";
}
.halflings-forward::after {
  content: "\e956";
}
.halflings-skip-to-end::after {
  content: "\e957";
}
.halflings-skip-to-next::after {
  content: "\e958";
}
.halflings-eject::after {
  content: "\e959";
}
.halflings-mobile-phone::after {
  content: "\e95a";
}
.halflings-mobile-phone-off::after {
  content: "\e95b";
}
.halflings-video::after {
  content: "\e95c";
}
.halflings-video-off::after {
  content: "\e95d";
}
.halflings-bluetooth::after {
  content: "\e95e";
}
.halflings-volume-off::after {
  content: "\e95f";
}
.halflings-volume-down::after {
  content: "\e960";
}
.halflings-volume-up::after {
  content: "\e961";
}
.halflings-circle-plus::after {
  content: "\e962";
}
.halflings-circle-minus::after {
  content: "\e963";
}
.halflings-circle-remove::after {
  content: "\e964";
}
.halflings-circle-check::after {
  content: "\e965";
}
.halflings-circle-question::after {
  content: "\e966";
}
.halflings-circle-info::after {
  content: "\e967";
}
.halflings-circle-alert::after {
  content: "\e968";
}
.halflings-open::after {
  content: "\e969";
}
.halflings-save::after {
  content: "\e96a";
}
.halflings-save-as::after {
  content: "\e96b";
}
.halflings-cart::after {
  content: "\e96c";
}
.halflings-cart-in::after {
  content: "\e96d";
}
.halflings-cart-out::after {
  content: "\e96e";
}
.halflings-circle-play::after {
  content: "\e96f";
}
.halflings-circle-down::after {
  content: "\e970";
}
.halflings-circle-up::after {
  content: "\e971";
}
.halflings-circle-left::after {
  content: "\e972";
}
.halflings-circle-right::after {
  content: "\e973";
}
.halflings-arrow-down::after {
  content: "\e974";
}
.halflings-arrow-up::after {
  content: "\e975";
}
.halflings-arrow-left::after {
  content: "\e976";
}
.halflings-arrow-right::after {
  content: "\e977";
}
.halflings-fullscreen-off::after {
  content: "\e978";
}
.halflings-fullscreen::after {
  content: "\e979";
}
.halflings-circle-empty::after {
  content: "\e97a";
}
.halflings-circle-selected::after {
  content: "\e97b";
}
.halflings-lock::after {
  content: "\e97c";
}
.halflings-lock-open::after {
  content: "\e97d";
}
.halflings-lock-off::after {
  content: "\e97e";
}
.halflings-chevron-down::after {
  content: "\e97f";
}
.halflings-chevron-up::after {
  content: "\e980";
}
.halflings-chevron-left::after {
  content: "\e981";
}
.halflings-chevron-right::after {
  content: "\e982";
}
.halflings-moon::after {
  content: "\e983";
}
.halflings-sun::after {
  content: "\e984";
}
.halflings-chat-conversation::after {
  content: "\e985";
}
.halflings-chat-message::after {
  content: "\e986";
}
.halflings-drop-off::after {
  content: "\e987";
}
.halflings-eraser::after {
  content: "\e988";
}
.halflings-anchor::after {
  content: "\e989";
}
.halflings-direction::after {
  content: "\e98a";
}
.halflings-flag::after {
  content: "\e98b";
}
.halflings-flag-triangle::after {
  content: "\e98c";
}
.halflings-cutlery::after {
  content: "\e98d";
}
.halflings-coffee-mug::after {
  content: "\e98e";
}
.halflings-square-plus::after {
  content: "\e98f";
}
.halflings-square-minus::after {
  content: "\e990";
}
.halflings-square-remove::after {
  content: "\e991";
}
.halflings-square-check::after {
  content: "\e992";
}
.halflings-square-question::after {
  content: "\e993";
}
.halflings-square-info::after {
  content: "\e994";
}
.halflings-square-alert::after {
  content: "\e995";
}
.halflings-square-upload::after {
  content: "\e996";
}
.halflings-square-download::after {
  content: "\e997";
}
.halflings-verify-empty::after {
  content: "\e998";
}
.halflings-verify-check::after {
  content: "\e999";
}
.halflings-no-symbol::after {
  content: "\e99a";
}
.halflings-square-play::after {
  content: "\e99b";
}
.halflings-square-down::after {
  content: "\e99c";
}
.halflings-square-up::after {
  content: "\e99d";
}
.halflings-square-left::after {
  content: "\e99e";
}
.halflings-square-right::after {
  content: "\e99f";
}
.halflings-wrench::after {
  content: "\e9a0";
}
.halflings-mic::after {
  content: "\e9a1";
}
.halflings-mic-off::after {
  content: "\e9a2";
}
.halflings-credit-card::after {
  content: "\e9a3";
}
.halflings-credit-card-off::after {
  content: "\e9a4";
}
.halflings-shield-check::after {
  content: "\e9a5";
}
.halflings-link::after {
  content: "\e9a6";
}
.halflings-link-remove::after {
  content: "\e9a7";
}
.halflings-bank::after {
  content: "\e9a8";
}
.halflings-plus::after {
  content: "\e9a9";
}
.halflings-minus::after {
  content: "\e9aa";
}
.halflings-times::after {
  content: "\e9ab";
}
.halflings-division::after {
  content: "\e9ac";
}
.halflings-equals::after {
  content: "\e9ad";
}
.halflings-folder-open::after {
  content: "\e9ae";
}
.halflings-filter::after {
  content: "\e9af";
}
.halflings-hard-drive::after {
  content: "\e9b0";
}
.halflings-dice::after {
  content: "\e9b1";
}
.halflings-globe::after {
  content: "\e9b2";
}
.halflings-crown::after {
  content: "\e9b3";
}
.halflings-square-triangle-down::after {
  content: "\e9b4";
}
.halflings-square-triangle-up::after {
  content: "\e9b5";
}
.halflings-square-triangle-left::after {
  content: "\e9b6";
}
.halflings-square-triangle-right::after {
  content: "\e9b7";
}
.halflings-log-in::after {
  content: "\e9b8";
}
.halflings-log-out::after {
  content: "\e9b9";
}
.halflings-archive::after {
  content: "\e9ba";
}
.halflings-music-alt::after {
  content: "\e9bb";
}
.halflings-video-play-empty::after {
  content: "\e9bc";
}
.halflings-video-play::after {
  content: "\e9bd";
}
.halflings-bell::after {
  content: "\e9be";
}
.halflings-bell-off::after {
  content: "\e9bf";
}
.halflings-wallet::after {
  content: "\e9c0";
}
.halflings-briefcase::after {
  content: "\e9c1";
}
.halflings-send::after {
  content: "\e9c2";
}
.halflings-construction-cone::after {
  content: "\e9c3";
}
.halflings-call::after {
  content: "\e9c4";
}
.halflings-sort::after {
  content: "\e9c5";
}
.halflings-switch::after {
  content: "\e9c6";
}
.halflings-exchange::after {
  content: "\e9c7";
}
.halflings-push-pin::after {
  content: "\e9c8";
}
.halflings-more::after {
  content: "\e9c9";
}
.halflings-more-vertical::after {
  content: "\e9ca";
}
.halflings-hourglass::after {
  content: "\e9cb";
}
.halflings-progress::after {
  content: "\e9cc";
}
.halflings-barcode::after {
  content: "\e9cd";
}
.halflings-qr-code::after {
  content: "\e9ce";
}
.halflings-star-half::after {
  content: "\e9cf";
}
.halflings-cloud::after {
  content: "\e9d0";
}
.halflings-map-marker::after {
  content: "\e9d1";
}
.halflings-set::after {
  content: "\e9d2";
}
.halflings-set-down::after {
  content: "\e9d3";
}
.halflings-arrow-thin-down::after {
  content: "\e9d4";
}
.halflings-arrow-thin-up::after {
  content: "\e9d5";
}
.halflings-arrow-thin-left::after {
  content: "\e9d6";
}
.halflings-arrow-thin-right::after {
  content: "\e9d7";
}
.halflings-chevron-thin-down::after {
  content: "\e9d8";
}
.halflings-chevron-thin-up::after {
  content: "\e9d9";
}
.halflings-chevron-thin-left::after {
  content: "\e9da";
}
.halflings-chevron-thin-right::after {
  content: "\e9db";
}
.halflings-fire::after {
  content: "\e9dc";
}
.halflings-chevron-last-down::after {
  content: "\e9dd";
}
.halflings-chevron-last-up::after {
  content: "\e9de";
}
.halflings-chevron-last-left::after {
  content: "\e9df";
}
.halflings-chevron-last-right::after {
  content: "\e9e0";
}
.halflings-menu-close::after {
  content: "\e9e1";
}
.halflings-menu::after {
  content: "\e9e2";
}
.halflings-email::after {
  content: "\e9e3";
}
.halflings-octagon-remove-empty::after {
  content: "\e9e4";
}
.halflings-octagon-remove::after {
  content: "\e9e5";
}
.halflings-dashboard::after {
  content: "\e9e6";
}
.halflings-text-underline::after {
  content: "\e9e7";
}
.halflings-text-background::after {
  content: "\e9e8";
}
.halflings-header::after {
  content: "\e9e9";
}
.halflings-announcement::after {
  content: "\e9ea";
}
.halflings-basket::after {
  content: "\e9eb";
}
.halflings-basket-in::after {
  content: "\e9ec";
}
.halflings-basket-out::after {
  content: "\e9ed";
}
.halflings-check::after {
  content: "\e9ee";
}
.halflings-computer::after {
  content: "\e9ef";
}
.halflings-fan::after {
  content: "\e9f0";
}
.halflings-flash::after {
  content: "\e9f1";
}
.halflings-slightly-smiling::after {
  content: "\e9f2";
}
.halflings-slightly-frowning::after {
  content: "\e9f3";
}
.halflings-reload-alt::after {
  content: "\e9f4";
}
.halflings-power-cord-plug::after {
  content: "\e9f5";
}
.halflings-power-cord-plug-off::after {
  content: "\e9f6";
}
.halflings-restart::after {
  content: "\e9f7";
}
.halflings-circle-more::after {
  content: "\e9f8";
}
.halflings-square-more::after {
  content: "\e9f9";
}
.halflings-circle-menu::after {
  content: "\e9fa";
}
.halflings-square-menu::after {
  content: "\e9fb";
}
.halflings-cloud-off::after {
  content: "\e9fc";
}
.halflings-layers::after {
  content: "\e9fd";
}
.halflings-airplane::after {
  content: "\e9fe";
}
.halflings-trending::after {
  content: "\e9ff";
}
.halflings-disappearing::after {
  content: "\ea00";
}
.halflings-rulers::after {
  content: "\ea01";
}
.halflings-switch-off::after {
  content: "\ea02";
}
.halflings-switch-on::after {
  content: "\ea03";
}
.halflings-usb-device::after {
  content: "\ea04";
}
.halflings-memory-card::after {
  content: "\ea05";
}
.halflings-axes-two-dimensional::after {
  content: "\ea06";
}
.halflings-axes-three-dimensional::after {
  content: "\ea07";
}
.halflings-spell-check::after {
  content: "\ea08";
}
.halflings-initial-letter::after {
  content: "\ea09";
}
.halflings-filter-off::after {
  content: "\ea0a";
}
.halflings-compound::after {
  content: "\ea0b";
}
.halflings-microchip::after {
  content: "\ea0c";
}
.halflings-satellite::after {
  content: "\ea0d";
}

.halflings-pacman::after {
  content: "\ee46";
}
