.glyphicons-file-text::after {
  content: "\edf6";
}
.glyphicons-file-rich-text::after {
  content: "\edf7";
}
.glyphicons-file-audio::after {
  content: "\edf8";
}
.glyphicons-file-music::after {
  content: "\edf9";
}
.glyphicons-file-video::after {
  content: "\edfa";
}
.glyphicons-file-image::after {
  content: "\edfb";
}
.glyphicons-file-photo::after {
  content: "\edfc";
}
.glyphicons-file-vector::after {
  content: "\edfd";
}
.glyphicons-file-spreadsheet::after {
  content: "\edfe";
}
.glyphicons-file-database::after {
  content: "\edff";
}
.glyphicons-file-program::after {
  content: "\ee00";
}
.glyphicons-file-terminal::after {
  content: "\ee01";
}
.glyphicons-file-lock::after {
  content: "\ee02";
}
.glyphicons-file-unlock::after {
  content: "\ee03";
}
.glyphicons-file-script::after {
  content: "\ee04";
}
.glyphicons-file-presentation::after {
  content: "\ee05";
}
.glyphicons-file-font::after {
  content: "\ee06";
}
.glyphicons-file-incoming::after {
  content: "\ee07";
}
.glyphicons-file-settings::after {
  content: "\ee08";
}
.glyphicons-file-web::after {
  content: "\ee09";
}
.glyphicons-file::after {
  content: "\ee0a";
}
.glyphicons-file-broken::after {
  content: "\ee0b";
}
.glyphicons-file-incomplete::after {
  content: "\ee0c";
}
.glyphicons-file-zip::after {
  content: "\ee0d";
}
.glyphicons-file-heart::after {
  content: "\ee0e";
}
.glyphicons-file-invisible::after {
  content: "\ee0f";
}
.glyphicons-file-visible::after {
  content: "\ee10";
}
.glyphicons-file-no::after {
  content: "\ee11";
}
.glyphicons-file-cloud::after {
  content: "\ee12";
}
.glyphicons-file-search::after {
  content: "\ee13";
}
.glyphicons-file-stats::after {
  content: "\ee14";
}
.glyphicons-file-package::after {
  content: "\ee15";
}
.glyphicons-file-disc-image::after {
  content: "\ee16";
}
.glyphicons-file-plus::after {
  content: "\ee17";
}
.glyphicons-file-minus::after {
  content: "\ee18";
}
.glyphicons-file-remove::after {
  content: "\ee19";
}
.glyphicons-file-check::after {
  content: "\ee1a";
}
.glyphicons-file-question::after {
  content: "\ee1b";
}
.glyphicons-file-info::after {
  content: "\ee1c";
}
.glyphicons-file-alert::after {
  content: "\ee1d";
}
.glyphicons-folder-applications::after {
  content: "\ee1e";
}
.glyphicons-folder-burnable::after {
  content: "\ee1f";
}
.glyphicons-folder-burnable-alt::after {
  content: "\ee20";
}
.glyphicons-folder-desktop::after {
  content: "\ee21";
}
.glyphicons-folder-settings::after {
  content: "\ee22";
}
.glyphicons-folder-documents::after {
  content: "\ee23";
}
.glyphicons-folder-downloads::after {
  content: "\ee24";
}
.glyphicons-folder-uploads::after {
  content: "\ee25";
}
.glyphicons-folder-favorites::after {
  content: "\ee26";
}
.glyphicons-folder-users::after {
  content: "\ee27";
}
.glyphicons-folder-music::after {
  content: "\ee28";
}
.glyphicons-folder-video::after {
  content: "\ee29";
}
.glyphicons-folder-image::after {
  content: "\ee2a";
}
.glyphicons-folder-photo::after {
  content: "\ee2b";
}
.glyphicons-folder-shared::after {
  content: "\ee2c";
}
.glyphicons-folder-smart::after {
  content: "\ee2d";
}
.glyphicons-folder-lock::after {
  content: "\ee2e";
}
.glyphicons-folder-unlock::after {
  content: "\ee2f";
}
.glyphicons-folder-user::after {
  content: "\ee30";
}
.glyphicons-folder-heart::after {
  content: "\ee31";
}
.glyphicons-folder::after {
  content: "\ee32";
}
.glyphicons-folder-open::after {
  content: "\ee33";
}
.glyphicons-folder-archive::after {
  content: "\ee34";
}
.glyphicons-folder-network::after {
  content: "\ee35";
}
.glyphicons-folder-network-off::after {
  content: "\ee36";
}
.glyphicons-folder-invisible::after {
  content: "\ee37";
}
.glyphicons-folder-visible::after {
  content: "\ee38";
}
.glyphicons-folder-no::after {
  content: "\ee39";
}
.glyphicons-folder-cloud::after {
  content: "\ee3a";
}
.glyphicons-folder-search::after {
  content: "\ee3b";
}
.glyphicons-folder-home::after {
  content: "\ee3c";
}
.glyphicons-folder-library::after {
  content: "\ee3d";
}
.glyphicons-folder-games::after {
  content: "\ee3e";
}
.glyphicons-folder-plus::after {
  content: "\ee3f";
}
.glyphicons-folder-minus::after {
  content: "\ee40";
}
.glyphicons-folder-remove::after {
  content: "\ee41";
}
.glyphicons-folder-check::after {
  content: "\ee42";
}
.glyphicons-folder-question::after {
  content: "\ee43";
}
.glyphicons-folder-info::after {
  content: "\ee44";
}
.glyphicons-folder-alert::after {
  content: "\ee45";
}
