.glyphicons-glass::after {
  content: "\ea0e";
}
.glyphicons-leaf::after {
  content: "\ea0f";
}
.glyphicons-dog::after {
  content: "\ea10";
}
.glyphicons-user::after {
  content: "\ea11";
}
.glyphicons-settings::after {
  content: "\ea12";
}
.glyphicons-car::after {
  content: "\ea13";
}
.glyphicons-user-plus::after {
  content: "\ea14";
}
.glyphicons-user-minus::after {
  content: "\ea15";
}
.glyphicons-film::after {
  content: "\ea16";
}
.glyphicons-magic-wand::after {
  content: "\ea17";
}
.glyphicons-envelope::after {
  content: "\ea18";
}
.glyphicons-camera::after {
  content: "\ea19";
}
.glyphicons-heart::after {
  content: "\ea1a";
}
.glyphicons-beach-umbrella::after {
  content: "\ea1b";
}
.glyphicons-train::after {
  content: "\ea1c";
}
.glyphicons-print::after {
  content: "\ea1d";
}
.glyphicons-bin::after {
  content: "\ea1e";
}
.glyphicons-music::after {
  content: "\ea1f";
}
.glyphicons-music-alt::after {
  content: "\ea20";
}
.glyphicons-heart-empty::after {
  content: "\ea21";
}
.glyphicons-home::after {
  content: "\ea22";
}
.glyphicons-snowflake::after {
  content: "\ea23";
}
.glyphicons-fire::after {
  content: "\ea24";
}
.glyphicons-magnet::after {
  content: "\ea25";
}
.glyphicons-user-parents::after {
  content: "\ea26";
}
.glyphicons-binoculars::after {
  content: "\ea27";
}
.glyphicons-road::after {
  content: "\ea28";
}
.glyphicons-search::after {
  content: "\ea29";
}
.glyphicons-cars::after {
  content: "\ea2a";
}
.glyphicons-clipboard::after {
  content: "\ea2b";
}
.glyphicons-pencil::after {
  content: "\ea2c";
}
.glyphicons-bus::after {
  content: "\ea2d";
}
.glyphicons-access-point::after {
  content: "\ea2e";
}
.glyphicons-luggage::after {
  content: "\ea2f";
}
.glyphicons-user-incognito::after {
  content: "\ea30";
}
.glyphicons-user-female::after {
  content: "\ea31";
}
.glyphicons-file::after {
  content: "\ea32";
}
.glyphicons-picture::after {
  content: "\ea33";
}
.glyphicons-airplane::after {
  content: "\ea34";
}
.glyphicons-notes::after {
  content: "\ea35";
}
.glyphicons-stats::after {
  content: "\ea36";
}
.glyphicons-stats-bars::after {
  content: "\ea37";
}
.glyphicons-stats-circle::after {
  content: "\ea38";
}
.glyphicons-user-group::after {
  content: "\ea39";
}
.glyphicons-key::after {
  content: "\ea3a";
}
.glyphicons-calendar::after {
  content: "\ea3b";
}
.glyphicons-router::after {
  content: "\ea3c";
}
.glyphicons-video-camera::after {
  content: "\ea3d";
}
.glyphicons-star::after {
  content: "\ea3e";
}
.glyphicons-star-empty::after {
  content: "\ea3f";
}
.glyphicons-pulse::after {
  content: "\ea40";
}
.glyphicons-eye::after {
  content: "\ea41";
}
.glyphicons-eye-off::after {
  content: "\ea42";
}
.glyphicons-alarm::after {
  content: "\ea43";
}
.glyphicons-clock::after {
  content: "\ea44";
}
.glyphicons-stopwatch::after {
  content: "\ea45";
}
.glyphicons-scissors-horizontal::after {
  content: "\ea46";
}
.glyphicons-history::after {
  content: "\ea47";
}
.glyphicons-truck::after {
  content: "\ea48";
}
.glyphicons-timer::after {
  content: "\ea49";
}
.glyphicons-compass::after {
  content: "\ea4a";
}
.glyphicons-presentation::after {
  content: "\ea4b";
}
.glyphicons-paperclip::after {
  content: "\ea4c";
}
.glyphicons-power::after {
  content: "\ea4d";
}
.glyphicons-lightbulb::after {
  content: "\ea4e";
}
.glyphicons-tag::after {
  content: "\ea4f";
}
.glyphicons-tags::after {
  content: "\ea50";
}
.glyphicons-education::after {
  content: "\ea51";
}
.glyphicons-ruler::after {
  content: "\ea52";
}
.glyphicons-gift::after {
  content: "\ea53";
}
.glyphicons-umbrella::after {
  content: "\ea54";
}
.glyphicons-book::after {
  content: "\ea55";
}
.glyphicons-bookmark::after {
  content: "\ea56";
}
.glyphicons-wifi::after {
  content: "\ea57";
}
.glyphicons-cup::after {
  content: "\ea58";
}
.glyphicons-stroller::after {
  content: "\ea59";
}
.glyphicons-headphones::after {
  content: "\ea5a";
}
.glyphicons-headset::after {
  content: "\ea5b";
}
.glyphicons-triangle-empty-alert::after {
  content: "\ea5c";
}
.glyphicons-bars::after {
  content: "\ea5d";
}
.glyphicons-retweet::after {
  content: "\ea5e";
}
.glyphicons-refresh::after {
  content: "\ea5f";
}
.glyphicons-roundabout::after {
  content: "\ea60";
}
.glyphicons-random::after {
  content: "\ea61";
}
.glyphicons-heating::after {
  content: "\ea62";
}
.glyphicons-reload::after {
  content: "\ea63";
}
.glyphicons-tv::after {
  content: "\ea64";
}
.glyphicons-book-log::after {
  content: "\ea65";
}
.glyphicons-book-address::after {
  content: "\ea66";
}
.glyphicons-building::after {
  content: "\ea67";
}
.glyphicons-eyedropper::after {
  content: "\ea68";
}
.glyphicons-contrast::after {
  content: "\ea69";
}
.glyphicons-drop::after {
  content: "\ea6a";
}
.glyphicons-crop::after {
  content: "\ea6b";
}
.glyphicons-vector-path::after {
  content: "\ea6c";
}
.glyphicons-vector-path-circle::after {
  content: "\ea6d";
}
.glyphicons-vector-path-pentagon::after {
  content: "\ea6e";
}
.glyphicons-vector-path-line::after {
  content: "\ea6f";
}
.glyphicons-vector-path-curve::after {
  content: "\ea70";
}
.glyphicons-vector-path-edit::after {
  content: "\ea71";
}
.glyphicons-text::after {
  content: "\ea72";
}
.glyphicons-italic::after {
  content: "\ea73";
}
.glyphicons-bold::after {
  content: "\ea74";
}
.glyphicons-underline::after {
  content: "\ea75";
}
.glyphicons-strikethrough::after {
  content: "\ea76";
}
.glyphicons-text-height::after {
  content: "\ea77";
}
.glyphicons-text-width::after {
  content: "\ea78";
}
.glyphicons-text-resize::after {
  content: "\ea79";
}
.glyphicons-indent-left::after {
  content: "\ea7a";
}
.glyphicons-indent-right::after {
  content: "\ea7b";
}
.glyphicons-paragraph-left::after {
  content: "\ea7c";
}
.glyphicons-paragraph-center::after {
  content: "\ea7d";
}
.glyphicons-paragraph-right::after {
  content: "\ea7e";
}
.glyphicons-paragraph-justify::after {
  content: "\ea7f";
}
.glyphicons-list::after {
  content: "\ea80";
}
.glyphicons-text-smaller::after {
  content: "\ea81";
}
.glyphicons-text-bigger::after {
  content: "\ea82";
}
.glyphicons-terminal-isolated::after {
  content: "\ea83";
}
.glyphicons-code::after {
  content: "\ea84";
}
.glyphicons-table::after {
  content: "\ea85";
}
.glyphicons-envelope-full::after {
  content: "\ea86";
}
.glyphicons-envelope-empty::after {
  content: "\ea87";
}
.glyphicons-envelope-down::after {
  content: "\ea88";
}
.glyphicons-envelope-up::after {
  content: "\ea89";
}
.glyphicons-envelope-plus::after {
  content: "\ea8a";
}
.glyphicons-envelope-minus::after {
  content: "\ea8b";
}
.glyphicons-envelope-no::after {
  content: "\ea8c";
}
.glyphicons-envelope-flag::after {
  content: "\ea8d";
}
.glyphicons-envelope-lock::after {
  content: "\ea8e";
}
.glyphicons-envelope-star::after {
  content: "\ea8f";
}
.glyphicons-inbox::after {
  content: "\ea90";
}
.glyphicons-inbox-plus::after {
  content: "\ea91";
}
.glyphicons-inbox-minus::after {
  content: "\ea92";
}
.glyphicons-inbox-lock::after {
  content: "\ea93";
}
.glyphicons-inbox-down::after {
  content: "\ea94";
}
.glyphicons-inbox-up::after {
  content: "\ea95";
}
.glyphicons-cogwheel::after {
  content: "\ea96";
}
.glyphicons-cogwheels::after {
  content: "\ea97";
}
.glyphicons-curves::after {
  content: "\ea98";
}
.glyphicons-adjust::after {
  content: "\ea99";
}
.glyphicons-database::after {
  content: "\ea9a";
}
.glyphicons-database-plus::after {
  content: "\ea9b";
}
.glyphicons-database-minus::after {
  content: "\ea9c";
}
.glyphicons-database-search::after {
  content: "\ea9d";
}
.glyphicons-folder-open::after {
  content: "\ea9e";
}
.glyphicons-folder-plus::after {
  content: "\ea9f";
}
.glyphicons-folder-minus::after {
  content: "\eaa0";
}
.glyphicons-folder-refresh::after {
  content: "\eaa1";
}
.glyphicons-folder-check::after {
  content: "\eaa2";
}
.glyphicons-folder-star::after {
  content: "\eaa3";
}
.glyphicons-square-edit::after {
  content: "\eaa4";
}
.glyphicons-square-new-window::after {
  content: "\eaa5";
}
.glyphicons-square-checkbox::after {
  content: "\eaa6";
}
.glyphicons-square-empty::after {
  content: "\eaa7";
}
.glyphicons-square-selected::after {
  content: "\eaa8";
}
.glyphicons-square-indeterminate::after {
  content: "\eaa9";
}
.glyphicons-thumbnails::after {
  content: "\eaaa";
}
.glyphicons-thumbnails-small::after {
  content: "\eaab";
}
.glyphicons-thumbnails-list::after {
  content: "\eaac";
}
.glyphicons-playlist::after {
  content: "\eaad";
}
.glyphicons-computer-all-in-one::after {
  content: "\eaae";
}
.glyphicons-computer-pc-tower::after {
  content: "\eaaf";
}
.glyphicons-computer::after {
  content: "\eab0";
}
.glyphicons-notebook::after {
  content: "\eab1";
}
.glyphicons-tablet::after {
  content: "\eab2";
}
.glyphicons-mobile-phone::after {
  content: "\eab3";
}
.glyphicons-mobile-phone-off::after {
  content: "\eab4";
}
.glyphicons-mobile-phone-vibrations::after {
  content: "\eab5";
}
.glyphicons-mobile-phone-shaking::after {
  content: "\eab6";
}
.glyphicons-earphones::after {
  content: "\eab7";
}
.glyphicons-skip-to-previous::after {
  content: "\eab8";
}
.glyphicons-skip-to-start::after {
  content: "\eab9";
}
.glyphicons-rewind::after {
  content: "\eaba";
}
.glyphicons-pause::after {
  content: "\eabb";
}
.glyphicons-play::after {
  content: "\eabc";
}
.glyphicons-stop::after {
  content: "\eabd";
}
.glyphicons-forward::after {
  content: "\eabe";
}
.glyphicons-skip-to-end::after {
  content: "\eabf";
}
.glyphicons-skip-to-next::after {
  content: "\eac0";
}
.glyphicons-eject::after {
  content: "\eac1";
}
.glyphicons-video::after {
  content: "\eac2";
}
.glyphicons-video-off::after {
  content: "\eac3";
}
.glyphicons-volume-bluetooth::after {
  content: "\eac4";
}
.glyphicons-volume-off::after {
  content: "\eac5";
}
.glyphicons-volume-down::after {
  content: "\eac6";
}
.glyphicons-volume-up::after {
  content: "\eac7";
}
.glyphicons-volume-max::after {
  content: "\eac8";
}
.glyphicons-cd::after {
  content: "\eac9";
}
.glyphicons-mc::after {
  content: "\eaca";
}
.glyphicons-lp::after {
  content: "\eacb";
}
.glyphicons-circle-empty-plus::after {
  content: "\eacc";
}
.glyphicons-circle-empty-minus::after {
  content: "\eacd";
}
.glyphicons-circle-empty-remove::after {
  content: "\eace";
}
.glyphicons-circle-empty-check::after {
  content: "\eacf";
}
.glyphicons-circle-empty-question::after {
  content: "\ead0";
}
.glyphicons-circle-empty-info::after {
  content: "\ead1";
}
.glyphicons-circle-empty-alert::after {
  content: "\ead2";
}
.glyphicons-open::after {
  content: "\ead3";
}
.glyphicons-save::after {
  content: "\ead4";
}
.glyphicons-save-as::after {
  content: "\ead5";
}
.glyphicons-cart::after {
  content: "\ead6";
}
.glyphicons-cart-in::after {
  content: "\ead7";
}
.glyphicons-cart-out::after {
  content: "\ead8";
}
.glyphicons-cart-checkout::after {
  content: "\ead9";
}
.glyphicons-cart-check::after {
  content: "\eada";
}
.glyphicons-circle-empty-play::after {
  content: "\eadb";
}
.glyphicons-circle-empty-down::after {
  content: "\eadc";
}
.glyphicons-circle-empty-up::after {
  content: "\eadd";
}
.glyphicons-circle-empty-left::after {
  content: "\eade";
}
.glyphicons-circle-empty-right::after {
  content: "\eadf";
}
.glyphicons-arrow-down::after {
  content: "\eae0";
}
.glyphicons-arrow-up::after {
  content: "\eae1";
}
.glyphicons-arrow-left::after {
  content: "\eae2";
}
.glyphicons-arrow-right::after {
  content: "\eae3";
}
.glyphicons-fullscreen-off::after {
  content: "\eae4";
}
.glyphicons-fullscreen::after {
  content: "\eae5";
}
.glyphicons-lock::after {
  content: "\eae6";
}
.glyphicons-lock-open::after {
  content: "\eae7";
}
.glyphicons-coins::after {
  content: "\eae8";
}
.glyphicons-banknotes::after {
  content: "\eae9";
}
.glyphicons-chevron-down::after {
  content: "\eaea";
}
.glyphicons-chevron-up::after {
  content: "\eaeb";
}
.glyphicons-chevron-left::after {
  content: "\eaec";
}
.glyphicons-chevron-right::after {
  content: "\eaed";
}
.glyphicons-bitcoin::after {
  content: "\eaee";
}
.glyphicons-euro::after {
  content: "\eaef";
}
.glyphicons-dollar::after {
  content: "\eaf0";
}
.glyphicons-pound::after {
  content: "\eaf1";
}
.glyphicons-yen::after {
  content: "\eaf2";
}
.glyphicons-ruble::after {
  content: "\eaf3";
}
.glyphicons-moon::after {
  content: "\eaf4";
}
.glyphicons-sun::after {
  content: "\eaf5";
}
.glyphicons-lasso::after {
  content: "\eaf6";
}
.glyphicons-calculator::after {
  content: "\eaf7";
}
.glyphicons-chat-conversation::after {
  content: "\eaf8";
}
.glyphicons-chat-conversation-off::after {
  content: "\eaf9";
}
.glyphicons-chat-conversation-lock::after {
  content: "\eafa";
}
.glyphicons-chat-message::after {
  content: "\eafb";
}
.glyphicons-chat-message-lock::after {
  content: "\eafc";
}
.glyphicons-chat-message-check::after {
  content: "\eafd";
}
.glyphicons-brush::after {
  content: "\eafe";
}
.glyphicons-marker::after {
  content: "\eaff";
}
.glyphicons-pen::after {
  content: "\eb00";
}
.glyphicons-spray::after {
  content: "\eb01";
}
.glyphicons-fill::after {
  content: "\eb02";
}
.glyphicons-clean::after {
  content: "\eb03";
}
.glyphicons-drop-off::after {
  content: "\eb04";
}
.glyphicons-palette::after {
  content: "\eb05";
}
.glyphicons-diamond::after {
  content: "\eb06";
}
.glyphicons-eraser::after {
  content: "\eb07";
}
.glyphicons-diving-free::after {
  content: "\eb08";
}
.glyphicons-diving-scuba::after {
  content: "\eb09";
}
.glyphicons-boat::after {
  content: "\eb0a";
}
.glyphicons-swimming-pool::after {
  content: "\eb0b";
}
.glyphicons-buoy::after {
  content: "\eb0c";
}
.glyphicons-oxygen-bottles::after {
  content: "\eb0d";
}
.glyphicons-fins::after {
  content: "\eb0e";
}
.glyphicons-anchor::after {
  content: "\eb0f";
}
.glyphicons-fishes::after {
  content: "\eb10";
}
.glyphicons-fishes-bait::after {
  content: "\eb11";
}
.glyphicons-direction::after {
  content: "\eb12";
}
.glyphicons-direction-empty::after {
  content: "\eb13";
}
.glyphicons-direction-full::after {
  content: "\eb14";
}
.glyphicons-flag-waving::after {
  content: "\eb15";
}
.glyphicons-flag::after {
  content: "\eb16";
}
.glyphicons-flag-triangle::after {
  content: "\eb17";
}
.glyphicons-comments::after {
  content: "\eb18";
}
.glyphicons-comments-off::after {
  content: "\eb19";
}
.glyphicons-comments-lock::after {
  content: "\eb1a";
}
.glyphicons-cake::after {
  content: "\eb1b";
}
.glyphicons-set-wine::after {
  content: "\eb1c";
}
.glyphicons-glass-wine::after {
  content: "\eb1d";
}
.glyphicons-coffee-mug::after {
  content: "\eb1e";
}
.glyphicons-drink::after {
  content: "\eb1f";
}
.glyphicons-beer::after {
  content: "\eb20";
}
.glyphicons-beer-alt::after {
  content: "\eb21";
}
.glyphicons-fast-food::after {
  content: "\eb22";
}
.glyphicons-cutlery::after {
  content: "\eb23";
}
.glyphicons-blender::after {
  content: "\eb24";
}
.glyphicons-pizza::after {
  content: "\eb25";
}
.glyphicons-coffee-to-go::after {
  content: "\eb26";
}
.glyphicons-tea-pot::after {
  content: "\eb27";
}
.glyphicons-tea-mug::after {
  content: "\eb28";
}
.glyphicons-coffee-beans::after {
  content: "\eb29";
}
.glyphicons-tea-leaves::after {
  content: "\eb2a";
}
.glyphicons-french-press::after {
  content: "\eb2b";
}
.glyphicons-pot::after {
  content: "\eb2c";
}
.glyphicons-kettle::after {
  content: "\eb2d";
}
.glyphicons-grater::after {
  content: "\eb2e";
}
.glyphicons-cooks-hat::after {
  content: "\eb2f";
}
.glyphicons-square-empty-plus::after {
  content: "\eb30";
}
.glyphicons-square-empty-minus::after {
  content: "\eb31";
}
.glyphicons-square-empty-remove::after {
  content: "\eb32";
}
.glyphicons-square-empty-check::after {
  content: "\eb33";
}
.glyphicons-square-empty-question::after {
  content: "\eb34";
}
.glyphicons-square-empty-info::after {
  content: "\eb35";
}
.glyphicons-square-empty-alert::after {
  content: "\eb36";
}
.glyphicons-circle-empty::after {
  content: "\eb37";
}
.glyphicons-circle-selected::after {
  content: "\eb38";
}
.glyphicons-asian-food::after {
  content: "\eb39";
}
.glyphicons-square-upload::after {
  content: "\eb3a";
}
.glyphicons-square-download::after {
  content: "\eb3b";
}
.glyphicons-verify-empty::after {
  content: "\eb3c";
}
.glyphicons-verify-check::after {
  content: "\eb3d";
}
.glyphicons-no-symbol::after {
  content: "\eb3e";
}
.glyphicons-square-empty-play::after {
  content: "\eb3f";
}
.glyphicons-square-empty-download::after {
  content: "\eb40";
}
.glyphicons-square-empty-upload::after {
  content: "\eb41";
}
.glyphicons-square-empty-left::after {
  content: "\eb42";
}
.glyphicons-square-empty-right::after {
  content: "\eb43";
}
.glyphicons-spade::after {
  content: "\eb44";
}
.glyphicons-saw::after {
  content: "\eb45";
}
.glyphicons-claw-hammer::after {
  content: "\eb46";
}
.glyphicons-hammer::after {
  content: "\eb47";
}
.glyphicons-screwdriver::after {
  content: "\eb48";
}
.glyphicons-wrench::after {
  content: "\eb49";
}
.glyphicons-ax::after {
  content: "\eb4a";
}
.glyphicons-blacksmith::after {
  content: "\eb4b";
}
.glyphicons-saw-blade::after {
  content: "\eb4c";
}
.glyphicons-certificate::after {
  content: "\eb4d";
}
.glyphicons-users::after {
  content: "\eb4e";
}
.glyphicons-shield-check::after {
  content: "\eb4f";
}
.glyphicons-shield-half::after {
  content: "\eb50";
}
.glyphicons-shield-empty-star::after {
  content: "\eb51";
}
.glyphicons-shield-quarter::after {
  content: "\eb52";
}
.glyphicons-shield-star::after {
  content: "\eb53";
}
.glyphicons-contactless-lock::after {
  content: "\eb54";
}
.glyphicons-contactless::after {
  content: "\eb55";
}
.glyphicons-credit-card::after {
  content: "\eb56";
}
.glyphicons-credit-card-off::after {
  content: "\eb57";
}
.glyphicons-quad-bike::after {
  content: "\eb58";
}
.glyphicons-motorbike::after {
  content: "\eb59";
}
.glyphicons-squares::after {
  content: "\eb5a";
}
.glyphicons-brightness-up::after {
  content: "\eb5b";
}
.glyphicons-brightness-down::after {
  content: "\eb5c";
}
.glyphicons-folder::after {
  content: "\eb5d";
}
.glyphicons-folder-cogwheel::after {
  content: "\eb5e";
}
.glyphicons-folder-alert::after {
  content: "\eb5f";
}
.glyphicons-folder-lock::after {
  content: "\eb60";
}
.glyphicons-folder-no::after {
  content: "\eb61";
}
.glyphicons-mic::after {
  content: "\eb62";
}
.glyphicons-mic-off::after {
  content: "\eb63";
}
.glyphicons-repeat::after {
  content: "\eb64";
}
.glyphicons-repeat-once::after {
  content: "\eb65";
}
.glyphicons-electric-cord::after {
  content: "\eb66";
}
.glyphicons-self-winding-cord::after {
  content: "\eb67";
}
.glyphicons-id-badge::after {
  content: "\eb68";
}
.glyphicons-id-badge-vertical::after {
  content: "\eb69";
}
.glyphicons-id-binder::after {
  content: "\eb6a";
}
.glyphicons-lock-off::after {
  content: "\eb6b";
}
.glyphicons-link::after {
  content: "\eb6c";
}
.glyphicons-link-remove::after {
  content: "\eb6d";
}
.glyphicons-bank::after {
  content: "\eb6e";
}
.glyphicons-scissors-cutting::after {
  content: "\eb6f";
}
.glyphicons-mouse::after {
  content: "\eb70";
}
.glyphicons-mouse-wireless::after {
  content: "\eb71";
}
.glyphicons-keyboard::after {
  content: "\eb72";
}
.glyphicons-keyboard-wireless::after {
  content: "\eb73";
}
.glyphicons-graphic-tablet::after {
  content: "\eb74";
}
.glyphicons-graphic-tablet-wireless::after {
  content: "\eb75";
}
.glyphicons-t-shirt::after {
  content: "\eb76";
}
.glyphicons-t-shirt-v::after {
  content: "\eb77";
}
.glyphicons-t-shirt-sleeves::after {
  content: "\eb78";
}
.glyphicons-shirt::after {
  content: "\eb79";
}
.glyphicons-tie::after {
  content: "\eb7a";
}
.glyphicons-trousers::after {
  content: "\eb7b";
}
.glyphicons-dress::after {
  content: "\eb7c";
}
.glyphicons-underwear::after {
  content: "\eb7d";
}
.glyphicons-fabric::after {
  content: "\eb7e";
}
.glyphicons-leather::after {
  content: "\eb7f";
}
.glyphicons-plus::after {
  content: "\eb80";
}
.glyphicons-minus::after {
  content: "\eb81";
}
.glyphicons-times::after {
  content: "\eb82";
}
.glyphicons-division::after {
  content: "\eb83";
}
.glyphicons-equals::after {
  content: "\eb84";
}
.glyphicons-hanger::after {
  content: "\eb85";
}
.glyphicons-flower::after {
  content: "\eb86";
}
.glyphicons-plant::after {
  content: "\eb87";
}
.glyphicons-tree-conifer::after {
  content: "\eb88";
}
.glyphicons-tree-deciduous::after {
  content: "\eb89";
}
.glyphicons-bowling::after {
  content: "\eb8a";
}
.glyphicons-rugby::after {
  content: "\eb8b";
}
.glyphicons-table-tennis::after {
  content: "\eb8c";
}
.glyphicons-basketball::after {
  content: "\eb8d";
}
.glyphicons-volleyball::after {
  content: "\eb8e";
}
.glyphicons-football::after {
  content: "\eb8f";
}
.glyphicons-baseball::after {
  content: "\eb90";
}
.glyphicons-hockey::after {
  content: "\eb91";
}
.glyphicons-golf::after {
  content: "\eb92";
}
.glyphicons-golf-green::after {
  content: "\eb93";
}
.glyphicons-winners::after {
  content: "\eb94";
}
.glyphicons-winners-stars::after {
  content: "\eb95";
}
.glyphicons-medal::after {
  content: "\eb96";
}
.glyphicons-dumbbell::after {
  content: "\eb97";
}
.glyphicons-filter::after {
  content: "\eb98";
}
.glyphicons-filter-plus::after {
  content: "\eb99";
}
.glyphicons-filter-minus::after {
  content: "\eb9a";
}
.glyphicons-filter-cogwheel::after {
  content: "\eb9b";
}
.glyphicons-import::after {
  content: "\eb9c";
}
.glyphicons-export::after {
  content: "\eb9d";
}
.glyphicons-hard-drive::after {
  content: "\eb9e";
}
.glyphicons-hard-drive-cogwheel::after {
  content: "\eb9f";
}
.glyphicons-hard-drive-lock::after {
  content: "\eba0";
}
.glyphicons-hard-drive-eject::after {
  content: "\eba1";
}
.glyphicons-turtle::after {
  content: "\eba2";
}
.glyphicons-rabbit::after {
  content: "\eba3";
}
.glyphicons-cat::after {
  content: "\eba4";
}
.glyphicons-sailboat::after {
  content: "\eba5";
}
.glyphicons-webcam::after {
  content: "\eba6";
}
.glyphicons-projector::after {
  content: "\eba7";
}
.glyphicons-shopping-bag::after {
  content: "\eba8";
}
.glyphicons-dice::after {
  content: "\eba9";
}
.glyphicons-game-controller::after {
  content: "\ebaa";
}
.glyphicons-game-controller-wireless::after {
  content: "\ebab";
}
.glyphicons-push-pin::after {
  content: "\ebac";
}
.glyphicons-id-badge-lanyard::after {
  content: "\ebad";
}
.glyphicons-globe::after {
  content: "\ebae";
}
.glyphicons-world-west::after {
  content: "\ebaf";
}
.glyphicons-worl-east::after {
  content: "\ebb0";
}
.glyphicons-vases::after {
  content: "\ebb1";
}
.glyphicons-book-play::after {
  content: "\ebb2";
}
.glyphicons-book-library::after {
  content: "\ebb3";
}
.glyphicons-briefcase-alt::after {
  content: "\ebb4";
}
.glyphicons-blackboard::after {
  content: "\ebb5";
}
.glyphicons-crown::after {
  content: "\ebb6";
}
.glyphicons-home-flag::after {
  content: "\ebb7";
}
.glyphicons-square-triangle-down::after {
  content: "\ebb8";
}
.glyphicons-square-triangle-up::after {
  content: "\ebb9";
}
.glyphicons-square-triangle-left::after {
  content: "\ebba";
}
.glyphicons-square-triangle-right::after {
  content: "\ebbb";
}
.glyphicons-log-in::after {
  content: "\ebbc";
}
.glyphicons-log-out::after {
  content: "\ebbd";
}
.glyphicons-reply::after {
  content: "\ebbe";
}
.glyphicons-reply-all::after {
  content: "\ebbf";
}
.glyphicons-forward-email::after {
  content: "\ebc0";
}
.glyphicons-archive::after {
  content: "\ebc1";
}
.glyphicons-playlist-music::after {
  content: "\ebc2";
}
.glyphicons-playlist-video::after {
  content: "\ebc3";
}
.glyphicons-video-play-empty::after {
  content: "\ebc4";
}
.glyphicons-video-play::after {
  content: "\ebc5";
}
.glyphicons-bell::after {
  content: "\ebc6";
}
.glyphicons-bell-off::after {
  content: "\ebc7";
}
.glyphicons-bell-ringing::after {
  content: "\ebc8";
}
.glyphicons-sampler::after {
  content: "\ebc9";
}
.glyphicons-wallet::after {
  content: "\ebca";
}
.glyphicons-wallet-plus::after {
  content: "\ebcb";
}
.glyphicons-wallet-minus::after {
  content: "\ebcc";
}
.glyphicons-wallet-lock::after {
  content: "\ebcd";
}
.glyphicons-wallet-cogwheel::after {
  content: "\ebce";
}
.glyphicons-wallet-check::after {
  content: "\ebcf";
}
.glyphicons-hand-like::after {
  content: "\ebd0";
}
.glyphicons-hand-dislike::after {
  content: "\ebd1";
}
.glyphicons-hand-pointing-right::after {
  content: "\ebd2";
}
.glyphicons-hand-pointing-left::after {
  content: "\ebd3";
}
.glyphicons-hand-pointing-up::after {
  content: "\ebd4";
}
.glyphicons-hand-pointing-down::after {
  content: "\ebd5";
}
.glyphicons-hand-open::after {
  content: "\ebd6";
}
.glyphicons-hand-open-alt::after {
  content: "\ebd7";
}
.glyphicons-hand-ok::after {
  content: "\ebd8";
}
.glyphicons-briefcase::after {
  content: "\ebd9";
}
.glyphicons-send::after {
  content: "\ebda";
}
.glyphicons-footprints::after {
  content: "\ebdb";
}
.glyphicons-construction-cone::after {
  content: "\ebdc";
}
.glyphicons-phone-old::after {
  content: "\ebdd";
}
.glyphicons-call::after {
  content: "\ebde";
}
.glyphicons-fax::after {
  content: "\ebdf";
}
.glyphicons-shredder::after {
  content: "\ebe0";
}
.glyphicons-multifunction-printer::after {
  content: "\ebe1";
}
.glyphicons-scanner::after {
  content: "\ebe2";
}
.glyphicons-security-camera::after {
  content: "\ebe3";
}
.glyphicons-sort::after {
  content: "\ebe4";
}
.glyphicons-sort-alphabetically::after {
  content: "\ebe5";
}
.glyphicons-sort-alphabetically-alt::after {
  content: "\ebe6";
}
.glyphicons-sort-numerically::after {
  content: "\ebe7";
}
.glyphicons-sort-numerically-alt::after {
  content: "\ebe8";
}
.glyphicons-sort-attributes::after {
  content: "\ebe9";
}
.glyphicons-sort-attributes-alt::after {
  content: "\ebea";
}
.glyphicons-switch::after {
  content: "\ebeb";
}
.glyphicons-user-scam::after {
  content: "\ebec";
}
.glyphicons-user-scam-alt::after {
  content: "\ebed";
}
.glyphicons-shop-window::after {
  content: "\ebee";
}
.glyphicons-shop-door::after {
  content: "\ebef";
}
.glyphicons-kiosk::after {
  content: "\ebf0";
}
.glyphicons-kiosk-wheels::after {
  content: "\ebf1";
}
.glyphicons-kiosk-immobile::after {
  content: "\ebf2";
}
.glyphicons-kiosk-food::after {
  content: "\ebf3";
}
.glyphicons-exchange::after {
  content: "\ebf4";
}
.glyphicons-bullets::after {
  content: "\ebf5";
}
.glyphicons-gun::after {
  content: "\ebf6";
}
.glyphicons-gun-off::after {
  content: "\ebf7";
}
.glyphicons-charging-station::after {
  content: "\ebf8";
}
.glyphicons-gas-station::after {
  content: "\ebf9";
}
.glyphicons-rotate::after {
  content: "\ebfa";
}
.glyphicons-rotate-horizontal::after {
  content: "\ebfb";
}
.glyphicons-rotate-vertical::after {
  content: "\ebfc";
}
.glyphicons-podcast::after {
  content: "\ebfd";
}
.glyphicons-more::after {
  content: "\ebfe";
}
.glyphicons-more-vertical::after {
  content: "\ebff";
}
.glyphicons-skull-crossbones::after {
  content: "\ec00";
}
.glyphicons-skull::after {
  content: "\ec01";
}
.glyphicons-server::after {
  content: "\ec02";
}
.glyphicons-server-plus::after {
  content: "\ec03";
}
.glyphicons-server-minus::after {
  content: "\ec04";
}
.glyphicons-server-no::after {
  content: "\ec05";
}
.glyphicons-server-check::after {
  content: "\ec06";
}
.glyphicons-server-lock::after {
  content: "\ec07";
}
.glyphicons-server-refresh::after {
  content: "\ec08";
}
.glyphicons-server-flag::after {
  content: "\ec09";
}
.glyphicons-server-cogwheel::after {
  content: "\ec0a";
}
.glyphicons-light-beacon::after {
  content: "\ec0b";
}
.glyphicons-bed::after {
  content: "\ec0c";
}
.glyphicons-bed-single::after {
  content: "\ec0d";
}
.glyphicons-cot::after {
  content: "\ec0e";
}
.glyphicons-personal-hygiene::after {
  content: "\ec0f";
}
.glyphicons-hairdresser::after {
  content: "\ec10";
}
.glyphicons-bed-sleeping::after {
  content: "\ec11";
}
.glyphicons-food-service::after {
  content: "\ec12";
}
.glyphicons-lamp-table::after {
  content: "\ec13";
}
.glyphicons-lamp::after {
  content: "\ec14";
}
.glyphicons-hourglass::after {
  content: "\ec15";
}
.glyphicons-monitor::after {
  content: "\ec16";
}
.glyphicons-monitors::after {
  content: "\ec17";
}
.glyphicons-monitor-rotate::after {
  content: "\ec18";
}
.glyphicons-monitor-vertical::after {
  content: "\ec19";
}
.glyphicons-phone-to-horizontal::after {
  content: "\ec1a";
}
.glyphicons-phone-rotate::after {
  content: "\ec1b";
}
.glyphicons-phone-horizontal::after {
  content: "\ec1c";
}
.glyphicons-shower::after {
  content: "\ec1d";
}
.glyphicons-shower-alt::after {
  content: "\ec1e";
}
.glyphicons-bath::after {
  content: "\ec1f";
}
.glyphicons-user-parents-alt::after {
  content: "\ec20";
}
.glyphicons-user-family::after {
  content: "\ec21";
}
.glyphicons-user-child::after {
  content: "\ec22";
}
.glyphicons-user-boy::after {
  content: "\ec23";
}
.glyphicons-user-girl::after {
  content: "\ec24";
}
.glyphicons-speakers::after {
  content: "\ec25";
}
.glyphicons-progress::after {
  content: "\ec26";
}
.glyphicons-tasks::after {
  content: "\ec27";
}
.glyphicons-barcode::after {
  content: "\ec28";
}
.glyphicons-qr-code::after {
  content: "\ec29";
}
.glyphicons-credit-empty::after {
  content: "\ec2a";
}
.glyphicons-credit::after {
  content: "\ec2b";
}
.glyphicons-star-half::after {
  content: "\ec2c";
}
.glyphicons-cloud::after {
  content: "\ec2d";
}
.glyphicons-cloud-upload::after {
  content: "\ec2e";
}
.glyphicons-cloud-download::after {
  content: "\ec2f";
}
.glyphicons-cloud-refresh::after {
  content: "\ec30";
}
.glyphicons-cloud-alert::after {
  content: "\ec31";
}
.glyphicons-cloud-pause::after {
  content: "\ec32";
}
.glyphicons-cloud-check::after {
  content: "\ec33";
}
.glyphicons-bag::after {
  content: "\ec34";
}
.glyphicons-hand-donate::after {
  content: "\ec35";
}
.glyphicons-hand-heart::after {
  content: "\ec36";
}
.glyphicons-handshake::after {
  content: "\ec37";
}
.glyphicons-hand-praying::after {
  content: "\ec38";
}
.glyphicons-flash::after {
  content: "\ec39";
}
.glyphicons-flash-automatic::after {
  content: "\ec3a";
}
.glyphicons-flash-no::after {
  content: "\ec3b";
}
.glyphicons-target::after {
  content: "\ec3c";
}
.glyphicons-marriage::after {
  content: "\ec3d";
}
.glyphicons-shoes::after {
  content: "\ec3e";
}
.glyphicons-shoes-off::after {
  content: "\ec3f";
}
.glyphicons-card-hearts::after {
  content: "\ec40";
}
.glyphicons-card-spades::after {
  content: "\ec41";
}
.glyphicons-card-diamonds::after {
  content: "\ec42";
}
.glyphicons-card-clubs::after {
  content: "\ec43";
}
.glyphicons-bring-forward::after {
  content: "\ec44";
}
.glyphicons-bring-to-front::after {
  content: "\ec45";
}
.glyphicons-send-backward::after {
  content: "\ec46";
}
.glyphicons-send-to-back::after {
  content: "\ec47";
}
.glyphicons-washing-machine::after {
  content: "\ec48";
}
.glyphicons-dryer::after {
  content: "\ec49";
}
.glyphicons-dishwasher::after {
  content: "\ec4a";
}
.glyphicons-oven::after {
  content: "\ec4b";
}
.glyphicons-hob::after {
  content: "\ec4c";
}
.glyphicons-microwave::after {
  content: "\ec4d";
}
.glyphicons-cluster::after {
  content: "\ec4e";
}
.glyphicons-share::after {
  content: "\ec4f";
}
.glyphicons-translate-alt::after {
  content: "\ec50";
}
.glyphicons-translate::after {
  content: "\ec51";
}
.glyphicons-directions::after {
  content: "\ec52";
}
.glyphicons-directions-arrow::after {
  content: "\ec53";
}
.glyphicons-finish-line::after {
  content: "\ec54";
}
.glyphicons-radio::after {
  content: "\ec55";
}
.glyphicons-start::after {
  content: "\ec56";
}
.glyphicons-bomb::after {
  content: "\ec57";
}
.glyphicons-bug::after {
  content: "\ec58";
}
.glyphicons-book-open-text::after {
  content: "\ec59";
}
.glyphicons-book-open::after {
  content: "\ec5a";
}
.glyphicons-newspaper::after {
  content: "\ec5b";
}
.glyphicons-map-marker::after {
  content: "\ec5c";
}
.glyphicons-map::after {
  content: "\ec5d";
}
.glyphicons-route::after {
  content: "\ec5e";
}
.glyphicons-nearby::after {
  content: "\ec5f";
}
.glyphicons-nearby-alt::after {
  content: "\ec60";
}
.glyphicons-piggy-bank::after {
  content: "\ec61";
}
.glyphicons-piggy-bank-coins::after {
  content: "\ec62";
}
.glyphicons-sound-surround::after {
  content: "\ec63";
}
.glyphicons-menu-close::after {
  content: "\ec64";
}
.glyphicons-menu::after {
  content: "\ec65";
}
.glyphicons-user-no::after {
  content: "\ec66";
}
.glyphicons-user-lock::after {
  content: "\ec67";
}
.glyphicons-user-flag::after {
  content: "\ec68";
}
.glyphicons-user-star::after {
  content: "\ec69";
}
.glyphicons-user-alert::after {
  content: "\ec6a";
}
.glyphicons-user-chat::after {
  content: "\ec6b";
}
.glyphicons-user-check::after {
  content: "\ec6c";
}
.glyphicons-square-hospital::after {
  content: "\ec6d";
}
.glyphicons-circle-red-cross::after {
  content: "\ec6e";
}
.glyphicons-star-of-life::after {
  content: "\ec6f";
}
.glyphicons-copy-duplicate::after {
  content: "\ec70";
}
.glyphicons-scissors::after {
  content: "\ec71";
}
.glyphicons-paste::after {
  content: "\ec72";
}
.glyphicons-copy::after {
  content: "\ec73";
}
.glyphicons-balance-scales::after {
  content: "\ec74";
}
.glyphicons-court-gavel::after {
  content: "\ec75";
}
.glyphicons-court-gavel-alt::after {
  content: "\ec76";
}
.glyphicons-circle-hospital::after {
  content: "\ec77";
}
.glyphicons-square-red-cross::after {
  content: "\ec78";
}
.glyphicons-star-of-life-empty::after {
  content: "\ec79";
}
.glyphicons-scale-personal::after {
  content: "\ec7a";
}
.glyphicons-scale-kitchen::after {
  content: "\ec7b";
}
.glyphicons-fireworks::after {
  content: "\ec7c";
}
.glyphicons-fireworks-alt::after {
  content: "\ec7d";
}
.glyphicons-chemistry::after {
  content: "\ec7e";
}
.glyphicons-chemistry-alt::after {
  content: "\ec7f";
}
.glyphicons-syringe-empty::after {
  content: "\ec80";
}
.glyphicons-syringe::after {
  content: "\ec81";
}
.glyphicons-heartbeat::after {
  content: "\ec82";
}
.glyphicons-heart-broken::after {
  content: "\ec83";
}
.glyphicons-circle-plus::after {
  content: "\ec84";
}
.glyphicons-circle-minus::after {
  content: "\ec85";
}
.glyphicons-circle-remove::after {
  content: "\ec86";
}
.glyphicons-circle-check::after {
  content: "\ec87";
}
.glyphicons-circle-question::after {
  content: "\ec88";
}
.glyphicons-circle-info::after {
  content: "\ec89";
}
.glyphicons-circle-alert::after {
  content: "\ec8a";
}
.glyphicons-triangle-alert::after {
  content: "\ec8b";
}
.glyphicons-octagon-remove-empty::after {
  content: "\ec8c";
}
.glyphicons-octagon-remove::after {
  content: "\ec8d";
}
.glyphicons-triangle-radioactive::after {
  content: "\ec8e";
}
.glyphicons-radioactive::after {
  content: "\ec8f";
}
.glyphicons-compound::after {
  content: "\ec90";
}
.glyphicons-dna::after {
  content: "\ec91";
}
.glyphicons-biohazard::after {
  content: "\ec92";
}
.glyphicons-circle-play::after {
  content: "\ec93";
}
.glyphicons-circle-down::after {
  content: "\ec94";
}
.glyphicons-circle-up::after {
  content: "\ec95";
}
.glyphicons-circle-left::after {
  content: "\ec96";
}
.glyphicons-circle-right::after {
  content: "\ec97";
}
.glyphicons-king::after {
  content: "\ec98";
}
.glyphicons-queen::after {
  content: "\ec99";
}
.glyphicons-rook::after {
  content: "\ec9a";
}
.glyphicons-bishop::after {
  content: "\ec9b";
}
.glyphicons-knight::after {
  content: "\ec9c";
}
.glyphicons-pawn::after {
  content: "\ec9d";
}
.glyphicons-door::after {
  content: "\ec9e";
}
.glyphicons-bicycle::after {
  content: "\ec9f";
}
.glyphicons-id::after {
  content: "\eca0";
}
.glyphicons-payment::after {
  content: "\eca1";
}
.glyphicons-legal-section-sign::after {
  content: "\eca2";
}
.glyphicons-voicemail::after {
  content: "\eca3";
}
.glyphicons-equalizer-dots::after {
  content: "\eca4";
}
.glyphicons-equalizer-bars::after {
  content: "\eca5";
}
.glyphicons-taxi::after {
  content: "\eca6";
}
.glyphicons-transport-mixed::after {
  content: "\eca7";
}
.glyphicons-metro::after {
  content: "\eca8";
}
.glyphicons-car-rental::after {
  content: "\eca9";
}
.glyphicons-tram::after {
  content: "\ecaa";
}
.glyphicons-dashboard::after {
  content: "\ecab";
}
.glyphicons-mountains::after {
  content: "\ecac";
}
.glyphicons-forest::after {
  content: "\ecad";
}
.glyphicons-cableway::after {
  content: "\ecae";
}
.glyphicons-canister::after {
  content: "\ecaf";
}
.glyphicons-motor-oil::after {
  content: "\ecb0";
}
.glyphicons-car-battery::after {
  content: "\ecb1";
}
.glyphicons-car-wheel::after {
  content: "\ecb2";
}
.glyphicons-car-wheel-defect::after {
  content: "\ecb3";
}
.glyphicons-car-wheel-wrench::after {
  content: "\ecb4";
}
.glyphicons-car-wheel-wrench-alt::after {
  content: "\ecb5";
}
.glyphicons-suitcase-doctor::after {
  content: "\ecb6";
}
.glyphicons-tent::after {
  content: "\ecb7";
}
.glyphicons-key-rounded::after {
  content: "\ecb8";
}
.glyphicons-flashlight::after {
  content: "\ecb9";
}
.glyphicons-backpack::after {
  content: "\ecba";
}
.glyphicons-watch::after {
  content: "\ecbb";
}
.glyphicons-watch-squared::after {
  content: "\ecbc";
}
.glyphicons-smart-bracelet::after {
  content: "\ecbd";
}
.glyphicons-lighter::after {
  content: "\ecbe";
}
.glyphicons-multifunction-knife::after {
  content: "\ecbf";
}
.glyphicons-text-underline::after {
  content: "\ecc0";
}
.glyphicons-font::after {
  content: "\ecc1";
}
.glyphicons-superscript::after {
  content: "\ecc2";
}
.glyphicons-subscript::after {
  content: "\ecc3";
}
.glyphicons-text-background::after {
  content: "\ecc4";
}
.glyphicons-header::after {
  content: "\ecc5";
}
.glyphicons-directions-sign::after {
  content: "\ecc6";
}
.glyphicons-barbed-wire::after {
  content: "\ecc7";
}
.glyphicons-tractor::after {
  content: "\ecc8";
}
.glyphicons-fence::after {
  content: "\ecc9";
}
.glyphicons-ice-cream::after {
  content: "\ecca";
}
.glyphicons-ice-cream-off::after {
  content: "\eccb";
}
.glyphicons-remote-control::after {
  content: "\eccc";
}
.glyphicons-pills::after {
  content: "\eccd";
}
.glyphicons-medicine::after {
  content: "\ecce";
}
.glyphicons-park::after {
  content: "\eccf";
}
.glyphicons-dog-tags::after {
  content: "\ecd0";
}
.glyphicons-luggage-alt::after {
  content: "\ecd1";
}
.glyphicons-lawn-mower::after {
  content: "\ecd2";
}
.glyphicons-brick-wall::after {
  content: "\ecd3";
}
.glyphicons-voting-urn::after {
  content: "\ecd4";
}
.glyphicons-government::after {
  content: "\ecd5";
}
.glyphicons-announcement::after {
  content: "\ecd6";
}
.glyphicons-dustbin::after {
  content: "\ecd7";
}
.glyphicons-feather::after {
  content: "\ecd8";
}
.glyphicons-recycling::after {
  content: "\ecd9";
}
.glyphicons-home-fire::after {
  content: "\ecda";
}
.glyphicons-home-flood::after {
  content: "\ecdb";
}
.glyphicons-water::after {
  content: "\ecdc";
}
.glyphicons-home-for-sale::after {
  content: "\ecdd";
}
.glyphicons-campfire::after {
  content: "\ecde";
}
.glyphicons-fireplace::after {
  content: "\ecdf";
}
.glyphicons-basket::after {
  content: "\ece0";
}
.glyphicons-basket-in::after {
  content: "\ece1";
}
.glyphicons-basket-out::after {
  content: "\ece2";
}
.glyphicons-basket-checkout::after {
  content: "\ece3";
}
.glyphicons-basket-check::after {
  content: "\ece4";
}
.glyphicons-firefighters::after {
  content: "\ece5";
}
.glyphicons-police::after {
  content: "\ece6";
}
.glyphicons-ambulance::after {
  content: "\ece7";
}
.glyphicons-ear::after {
  content: "\ece8";
}
.glyphicons-ear-off::after {
  content: "\ece9";
}
.glyphicons-theater-masks::after {
  content: "\ecea";
}
.glyphicons-kettlebell::after {
  content: "\eceb";
}
.glyphicons-ticket::after {
  content: "\ecec";
}
.glyphicons-ticket-parking::after {
  content: "\eced";
}
.glyphicons-parking-meter::after {
  content: "\ecee";
}
.glyphicons-parking::after {
  content: "\ecef";
}
.glyphicons-check::after {
  content: "\ecf0";
}
.glyphicons-hash::after {
  content: "\ecf1";
}
.glyphicons-align-top::after {
  content: "\ecf2";
}
.glyphicons-align-bottom::after {
  content: "\ecf3";
}
.glyphicons-align-horizontal::after {
  content: "\ecf4";
}
.glyphicons-align-left::after {
  content: "\ecf5";
}
.glyphicons-align-vertical::after {
  content: "\ecf6";
}
.glyphicons-align-right::after {
  content: "\ecf7";
}
.glyphicons-reflect-y::after {
  content: "\ecf8";
}
.glyphicons-reflect-x::after {
  content: "\ecf9";
}
.glyphicons-resize-horizontal::after {
  content: "\ecfa";
}
.glyphicons-resize-vertical::after {
  content: "\ecfb";
}
.glyphicons-call-redirect::after {
  content: "\ecfc";
}
.glyphicons-call-ip::after {
  content: "\ecfd";
}
.glyphicons-call-record::after {
  content: "\ecfe";
}
.glyphicons-call-music::after {
  content: "\ecff";
}
.glyphicons-call-data::after {
  content: "\ed00";
}
.glyphicons-call-pause::after {
  content: "\ed01";
}
.glyphicons-call-incoming::after {
  content: "\ed02";
}
.glyphicons-call-outgoing::after {
  content: "\ed03";
}
.glyphicons-call-video::after {
  content: "\ed04";
}
.glyphicons-call-lock::after {
  content: "\ed05";
}
.glyphicons-sync::after {
  content: "\ed06";
}
.glyphicons-sync-alert::after {
  content: "\ed07";
}
.glyphicons-sync-check::after {
  content: "\ed08";
}
.glyphicons-modal-window::after {
  content: "\ed09";
}
.glyphicons-gas::after {
  content: "\ed0a";
}
.glyphicons-waste-pipe::after {
  content: "\ed0b";
}
.glyphicons-waste-pipe-end::after {
  content: "\ed0c";
}
.glyphicons-toilet::after {
  content: "\ed0d";
}
.glyphicons-drinking-water::after {
  content: "\ed0e";
}
.glyphicons-drinking-water-off::after {
  content: "\ed0f";
}
.glyphicons-apple::after {
  content: "\ed10";
}
.glyphicons-neighborhood::after {
  content: "\ed11";
}
.glyphicons-temperature::after {
  content: "\ed12";
}
.glyphicons-temperature-alert::after {
  content: "\ed13";
}
.glyphicons-temperature-set::after {
  content: "\ed14";
}
.glyphicons-temperature-low::after {
  content: "\ed15";
}
.glyphicons-temperature-high::after {
  content: "\ed16";
}
.glyphicons-tornado::after {
  content: "\ed17";
}
.glyphicons-ice-lolly::after {
  content: "\ed18";
}
.glyphicons-ice-lolly-eaten::after {
  content: "\ed19";
}
.glyphicons-milk::after {
  content: "\ed1a";
}
.glyphicons-egg::after {
  content: "\ed1b";
}
.glyphicons-wheat::after {
  content: "\ed1c";
}
.glyphicons-corn::after {
  content: "\ed1d";
}
.glyphicons-soya::after {
  content: "\ed1e";
}
.glyphicons-nuts::after {
  content: "\ed1f";
}
.glyphicons-carrot::after {
  content: "\ed20";
}
.glyphicons-meat::after {
  content: "\ed21";
}
.glyphicons-seafood::after {
  content: "\ed22";
}
.glyphicons-fish::after {
  content: "\ed23";
}
.glyphicons-square-email::after {
  content: "\ed24";
}
.glyphicons-email::after {
  content: "\ed25";
}
.glyphicons-paired::after {
  content: "\ed26";
}
.glyphicons-paired-off::after {
  content: "\ed27";
}
.glyphicons-set::after {
  content: "\ed28";
}
.glyphicons-set-down::after {
  content: "\ed29";
}
.glyphicons-chevron-last-down::after {
  content: "\ed2a";
}
.glyphicons-chevron-last-up::after {
  content: "\ed2b";
}
.glyphicons-chevron-last-left::after {
  content: "\ed2c";
}
.glyphicons-chevron-last-right::after {
  content: "\ed2d";
}
.glyphicons-file-plus::after {
  content: "\ed2e";
}
.glyphicons-file-minus::after {
  content: "\ed2f";
}
.glyphicons-file-refresh::after {
  content: "\ed30";
}
.glyphicons-file-lock::after {
  content: "\ed31";
}
.glyphicons-file-cloud::after {
  content: "\ed32";
}
.glyphicons-user-rounded::after {
  content: "\ed33";
}
.glyphicons-user-squared::after {
  content: "\ed34";
}
.glyphicons-drop-down::after {
  content: "\ed35";
}
.glyphicons-drop-plus::after {
  content: "\ed36";
}
.glyphicons-block-move::after {
  content: "\ed37";
}
.glyphicons-fingerprint-scan::after {
  content: "\ed38";
}
.glyphicons-fingerprint::after {
  content: "\ed39";
}
.glyphicons-fingerprint-lock::after {
  content: "\ed3a";
}
.glyphicons-fingerprint-check::after {
  content: "\ed3b";
}
.glyphicons-fingerprint-remove::after {
  content: "\ed3c";
}
.glyphicons-satellite::after {
  content: "\ed3d";
}
.glyphicons-satellite-dish::after {
  content: "\ed3e";
}
.glyphicons-satellite-dish-alt::after {
  content: "\ed3f";
}
.glyphicons-robot::after {
  content: "\ed40";
}
.glyphicons-radar::after {
  content: "\ed41";
}
.glyphicons-battery-charging::after {
  content: "\ed42";
}
.glyphicons-battery-charged::after {
  content: "\ed43";
}
.glyphicons-battery-three-quarters::after {
  content: "\ed44";
}
.glyphicons-battery-half::after {
  content: "\ed45";
}
.glyphicons-battery-quarter::after {
  content: "\ed46";
}
.glyphicons-battery-low::after {
  content: "\ed47";
}
.glyphicons-arrow-thin-down::after {
  content: "\ed48";
}
.glyphicons-arrow-thin-up::after {
  content: "\ed49";
}
.glyphicons-arrow-thin-left::after {
  content: "\ed4a";
}
.glyphicons-arrow-thin-right::after {
  content: "\ed4b";
}
.glyphicons-male::after {
  content: "\ed4c";
}
.glyphicons-female::after {
  content: "\ed4d";
}
.glyphicons-virgin-female::after {
  content: "\ed4e";
}
.glyphicons-intersex::after {
  content: "\ed4f";
}
.glyphicons-transgender::after {
  content: "\ed50";
}
.glyphicons-lesbian::after {
  content: "\ed51";
}
.glyphicons-gay::after {
  content: "\ed52";
}
.glyphicons-hetero::after {
  content: "\ed53";
}
.glyphicons-other::after {
  content: "\ed54";
}
.glyphicons-unknown::after {
  content: "\ed55";
}
.glyphicons-square-plus::after {
  content: "\ed56";
}
.glyphicons-square-minus::after {
  content: "\ed57";
}
.glyphicons-square-remove::after {
  content: "\ed58";
}
.glyphicons-square-check::after {
  content: "\ed59";
}
.glyphicons-square-question::after {
  content: "\ed5a";
}
.glyphicons-square-info::after {
  content: "\ed5b";
}
.glyphicons-square-alert::after {
  content: "\ed5c";
}
.glyphicons-subtitles::after {
  content: "\ed5d";
}
.glyphicons-computer-network::after {
  content: "\ed5e";
}
.glyphicons-computer-network-alt::after {
  content: "\ed5f";
}
.glyphicons-subtitles-off::after {
  content: "\ed60";
}
.glyphicons-subtitles-plus::after {
  content: "\ed61";
}
.glyphicons-subtitles-minus::after {
  content: "\ed62";
}
.glyphicons-audio-description-d::after {
  content: "\ed63";
}
.glyphicons-audio-description::after {
  content: "\ed64";
}
.glyphicons-square-play::after {
  content: "\ed65";
}
.glyphicons-square-down::after {
  content: "\ed66";
}
.glyphicons-square-up::after {
  content: "\ed67";
}
.glyphicons-square-left::after {
  content: "\ed68";
}
.glyphicons-square-right::after {
  content: "\ed69";
}
.glyphicons-vr-inside::after {
  content: "\ed6a";
}
.glyphicons-vr::after {
  content: "\ed6b";
}
.glyphicons-user-vr::after {
  content: "\ed6c";
}
.glyphicons-user-vr-plus::after {
  content: "\ed6d";
}
.glyphicons-user-vr-minus::after {
  content: "\ed6e";
}
.glyphicons-view-full::after {
  content: "\ed6f";
}
.glyphicons-view-half::after {
  content: "\ed70";
}
.glyphicons-puzzle::after {
  content: "\ed71";
}
.glyphicons-door-open::after {
  content: "\ed72";
}
.glyphicons-door-close::after {
  content: "\ed73";
}
.glyphicons-package::after {
  content: "\ed74";
}
.glyphicons-one-day-delivery::after {
  content: "\ed75";
}
.glyphicons-forklift::after {
  content: "\ed76";
}
.glyphicons-transport-trolley::after {
  content: "\ed77";
}
.glyphicons-simple-trolley::after {
  content: "\ed78";
}
.glyphicons-palette-package::after {
  content: "\ed79";
}
.glyphicons-warehouse::after {
  content: "\ed7a";
}
.glyphicons-this-side-up::after {
  content: "\ed7b";
}
.glyphicons-terminal-empty::after {
  content: "\ed7c";
}
.glyphicons-terminal::after {
  content: "\ed7d";
}
.glyphicons-candle::after {
  content: "\ed7e";
}
.glyphicons-balloon::after {
  content: "\ed7f";
}
.glyphicons-balloons::after {
  content: "\ed80";
}
.glyphicons-missile::after {
  content: "\ed81";
}
.glyphicons-step-back::after {
  content: "\ed82";
}
.glyphicons-step-forward::after {
  content: "\ed83";
}
.glyphicons-undo::after {
  content: "\ed84";
}
.glyphicons-redo::after {
  content: "\ed85";
}
.glyphicons-three-dimensional::after {
  content: "\ed86";
}
.glyphicons-three-dimensional-full::after {
  content: "\ed87";
}
.glyphicons-factory::after {
  content: "\ed88";
}
.glyphicons-factory-alt::after {
  content: "\ed89";
}
.glyphicons-user-worker::after {
  content: "\ed8a";
}
.glyphicons-barrel::after {
  content: "\ed8b";
}
.glyphicons-dice-one::after {
  content: "\ed8c";
}
.glyphicons-dice-two::after {
  content: "\ed8d";
}
.glyphicons-dice-three::after {
  content: "\ed8e";
}
.glyphicons-dice-four::after {
  content: "\ed8f";
}
.glyphicons-dice-five::after {
  content: "\ed90";
}
.glyphicons-dice-six::after {
  content: "\ed91";
}
.glyphicons-slightly-smiling::after {
  content: "\ed92";
}
.glyphicons-slightly-frowning::after {
  content: "\ed93";
}
.glyphicons-pool::after {
  content: "\ed94";
}
.glyphicons-tennis::after {
  content: "\ed95";
}
.glyphicons-badminton::after {
  content: "\ed96";
}
.glyphicons-restart::after {
  content: "\ed97";
}
.glyphicons-replay::after {
  content: "\ed98";
}
.glyphicons-honeycombs::after {
  content: "\ed99";
}
.glyphicons-honeybee::after {
  content: "\ed9a";
}
.glyphicons-honey::after {
  content: "\ed9b";
}
.glyphicons-fan::after {
  content: "\ed9c";
}
.glyphicons-wind::after {
  content: "\ed9d";
}
.glyphicons-rechargeable::after {
  content: "\ed9e";
}
.glyphicons-mobile-phone-lock::after {
  content: "\ed9f";
}
.glyphicons-mobile-phone-check::after {
  content: "\eda0";
}
.glyphicons-mobile-phone-cogwheel::after {
  content: "\eda1";
}
.glyphicons-mobile-phone-alert::after {
  content: "\eda2";
}
.glyphicons-power-plant-wind::after {
  content: "\eda3";
}
.glyphicons-power-plant-solar::after {
  content: "\eda4";
}
.glyphicons-power-plant-water::after {
  content: "\eda5";
}
.glyphicons-rewind-five::after {
  content: "\eda6";
}
.glyphicons-rewind-ten::after {
  content: "\eda7";
}
.glyphicons-rewind-fifteen::after {
  content: "\eda8";
}
.glyphicons-rewind-thirty::after {
  content: "\eda9";
}
.glyphicons-forward-five::after {
  content: "\edaa";
}
.glyphicons-forward-ten::after {
  content: "\edab";
}
.glyphicons-forward-fifteen::after {
  content: "\edac";
}
.glyphicons-forward-thirty::after {
  content: "\edad";
}
.glyphicons-passport::after {
  content: "\edae";
}
.glyphicons-passport-alt::after {
  content: "\edaf";
}
.glyphicons-speech-bubble-question::after {
  content: "\edb0";
}
.glyphicons-speech-bubble-info::after {
  content: "\edb1";
}
.glyphicons-speech-bubble-alert::after {
  content: "\edb2";
}
.glyphicons-reload-alt::after {
  content: "\edb3";
}
.glyphicons-power-cord-plug::after {
  content: "\edb4";
}
.glyphicons-power-cord-plug-off::after {
  content: "\edb5";
}
.glyphicons-wireless-charging::after {
  content: "\edb6";
}
.glyphicons-tv-streaming::after {
  content: "\edb7";
}
.glyphicons-cheque::after {
  content: "\edb8";
}
.glyphicons-cheque-off::after {
  content: "\edb9";
}
.glyphicons-buildings-mixed::after {
  content: "\edba";
}
.glyphicons-buildings-city::after {
  content: "\edbb";
}
.glyphicons-buildings-park::after {
  content: "\edbc";
}
.glyphicons-highway::after {
  content: "\edbd";
}
.glyphicons-traffic-lights::after {
  content: "\edbe";
}
.glyphicons-traffic-lights-off::after {
  content: "\edbf";
}
.glyphicons-circle-more::after {
  content: "\edc0";
}
.glyphicons-square-more::after {
  content: "\edc1";
}
.glyphicons-circle-menu::after {
  content: "\edc2";
}
.glyphicons-square-menu::after {
  content: "\edc3";
}
.glyphicons-list-letters::after {
  content: "\edc4";
}
.glyphicons-list-numbers::after {
  content: "\edc5";
}
.glyphicons-initial-letter::after {
  content: "\edc6";
}
.glyphicons-brain::after {
  content: "\edc7";
}
.glyphicons-brain-ai::after {
  content: "\edc8";
}
.glyphicons-artificial-intelligence::after {
  content: "\edc9";
}
.glyphicons-microchip::after {
  content: "\edca";
}
.glyphicons-filter-off::after {
  content: "\edcb";
}
.glyphicons-book-library-search::after {
  content: "\edcc";
}
.glyphicons-files-queue::after {
  content: "\edcd";
}
.glyphicons-cloud-off::after {
  content: "\edce";
}
.glyphicons-trending::after {
  content: "\edcf";
}
.glyphicons-disappearing::after {
  content: "\edd0";
}
.glyphicons-layers::after {
  content: "\edd1";
}
.glyphicons-layers-plus::after {
  content: "\edd2";
}
.glyphicons-layers-minus::after {
  content: "\edd3";
}
.glyphicons-layers-lock::after {
  content: "\edd4";
}
.glyphicons-layers-down::after {
  content: "\edd5";
}
.glyphicons-layers-up::after {
  content: "\edd6";
}
.glyphicons-layers-cogwheel::after {
  content: "\edd7";
}
.glyphicons-axes-three-dimensional::after {
  content: "\edd8";
}
.glyphicons-axes-two-dimensional::after {
  content: "\edd9";
}
.glyphicons-rulers::after {
  content: "\edda";
}
.glyphicons-spell-check::after {
  content: "\eddb";
}
.glyphicons-spell-check-check::after {
  content: "\eddc";
}
.glyphicons-globe-data::after {
  content: "\eddd";
}
.glyphicons-map-satellite::after {
  content: "\edde";
}
.glyphicons-map-cadastral::after {
  content: "\eddf";
}
.glyphicons-map-aerial::after {
  content: "\ede0";
}
.glyphicons-map-tourist::after {
  content: "\ede1";
}
.glyphicons-cookie::after {
  content: "\ede2";
}
.glyphicons-cookie-alt::after {
  content: "\ede3";
}
.glyphicons-cookies::after {
  content: "\ede4";
}
.glyphicons-cash-register::after {
  content: "\ede5";
}
.glyphicons-cash-register-alt::after {
  content: "\ede6";
}
.glyphicons-hard-drive-no::after {
  content: "\ede7";
}
.glyphicons-switch-off::after {
  content: "\ede8";
}
.glyphicons-switch-on::after {
  content: "\ede9";
}
.glyphicons-receipt::after {
  content: "\edea";
}
.glyphicons-invoice::after {
  content: "\edeb";
}
.glyphicons-usb-device::after {
  content: "\edec";
}
.glyphicons-usb-device-lock::after {
  content: "\eded";
}
.glyphicons-usb-device-no::after {
  content: "\edee";
}
.glyphicons-usb-device-cogwheel::after {
  content: "\edef";
}
.glyphicons-usb-device-eject::after {
  content: "\edf0";
}
.glyphicons-memory-card::after {
  content: "\edf1";
}
.glyphicons-memory-card-lock::after {
  content: "\edf2";
}
.glyphicons-memory-card-no::after {
  content: "\edf3";
}
.glyphicons-memory-card-cogwheel::after {
  content: "\edf4";
}
.glyphicons-memory-card-eject::after {
  content: "\edf5";
}
