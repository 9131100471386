// Override default variables before the import
@import '_colors';

// Import Bootstrap and its default variables
@import 'custom-bootstrap';

$topBarHeight: 76px;
$infoBarHeight: 84px;
$collapse-breakpoint: 992px;

* {
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
}

.container-fluid   {
  padding-bottom: 2rem;
}

.font-size-small {
  font-size: 14px;
}

.auth-wrapper {
  height: 100vh;
  background: $login-background-dark;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.checkbox-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.navbar-logo {
  height: 2rem;
}

.top-navbar {
  position: sticky!important;
  top: 0;
  z-index: 1000;
}

.flex-collapse {
  flex-direction: column;
}

@media (min-width: $collapse-breakpoint) {
  .flex-collapse {
    flex-direction: row;
  }

  .flex-collapse-center {
    align-items: center;
  }
}

.nav-item .glyphicons {
  font-size: 1.5rem;
  vertical-align: bottom;
}

.info-bar {
  background: $infoBarColor;
  height: $infoBarHeight;
  position: sticky;
  top: $topBarHeight;
  z-index: 100;
  font-size: 20px;
}

.slogan {
  color: #373736;
  font-size: 1.6rem;
}

.info-bar-back-button {
  color: white;
}

.info-bar-back-button .glyphicons {
  vertical-align: middle;
}

.info-bar-back-button:hover.pointer {
  cursor: pointer;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #555;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
  position: fixed;
  z-index: 999;
}

/* necessary for spinner to spin */
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

/* necessary for spinner to spin */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.arrow-sorted {
  font-size: 0.75rem!important;
  color: $primary-text-color;
}

.text-primary-color {
  color: $primary-text-color !important;
}

.table-large {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.risk-badge {
  font-size: 14px!important;
  min-width: 80px;
}
.badge-rounded-left {
  border-radius: 10rem 0 0 10rem!important;
}
.badge-rounded-right {
  border-radius: 0 10rem 10rem 0!important;
}

.badge-not-rounded {
  border-radius: 0!important;
}

.student-code95-card {
  top: $topBarHeight + $infoBarHeight - 60px;
  z-index: 200;
  position: sticky!important;
  margin-top: -6rem;
}

.student-code95-card .glyphicons-circle-plus {
  font-size:6.4rem;
}

div.table-search label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.table-search input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.pt-6 {
  padding-top: 6rem;
}

/* Bootstrap's rounded-lg * 2 */
.rounded-xl {
  border-radius: 0.6rem!important;
}

.opacity-3 {
  opacity: 0.3;
}

.font-size-large {
  font-size: 1.5rem!important;
}

.studentDetailsPanel {
  background-color: white;
  height: inherit;
  min-height: calc(100vh - 118px);
}

.toast-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  z-index: 1040;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.toast {
  min-width: 800px;
  max-height: 38px;

  &.show {
    display: table!important;
  }
}

.confirmation-toast.toast.show {
  min-width: 100%;
  width: 100%;

  .toast-header, .toast-body {
    width: inherit;
  }
}

.toast-success {
  background-color: #d4edda!important;
}

.toast-error {
  background-color: #007AFF !important;
}

.toast-error > .toast-header {
  color: white!important;
}

.toast-header {
  background-color: transparent!important;
}
.rounded-button {
  border-radius: 50%!important;
  height: 55px;
  width: 55px;
}

.rounded-button .glyphicons {
  font-size: 1.5rem;
}

.modal-header {
  background-color: $modalBarColor;
}

.modal-title {
  color: white;
}

.scrollable-vertical {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.glyphicons-bin::after {
  font-size: 1.4rem;
}

.glyphicons-circle-empty-alert::after {
  font-size: 2.5rem;
}

.glyphicons-navbar-icon::after,
{
  font-size: 1.7rem !important;
}

.glyphicons-disabled::after {
  color: #959798;
}

.close {
  color: white!important;
}

.glyphicons-grey {
  color: rgba(0,0,0, 0.7);
}

.code95-more {
  font-size: 2rem!important;
  cursor: pointer;
}

// Give span default height if empty
span.item:empty:before {
  content: "\200b"; // unicode zero width space character
}

// chip input
.chip-input {
  .no-border, .no-border:focus {
    @extend .border-0;
    outline: none;
  }
  height: 31px;
}

.chip-glyphicon {
  font-size: 0.984rem!important;
  line-height: 28px!important;
  width: 34px;
}

.chip-result-list {
  position: absolute;
  z-index: 100;
  width: 97%;
  left: 15px;
  right: 0;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 2px;
}

.chipTextInput {
  font-size: 0.984375rem;
}

.chipInputField {
  min-height: 35px;
  background-color: white;
}

.badge-wrapper {
  padding-top: 2px;
  padding-bottom: 2px;
}

.badge-chip {
  height: 29px;
}

.top-navbar .custom-brand {
  padding: 0;
}

.logo-image {
  height: 60px;
  padding: 5px 0;
  transform: translateY(-4px);
}

.input-group-append .date-picker-append {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

div.border-3 {
  border-width: 3px !important;
}

.active-tab {
  color: $modalBarColor !important;
  font-weight: bold !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-color: $modalBarColor !important;
}

.dropdown-menu-item:hover {
  background-color: #c1c1c1;
}

.custom-fade {
  position: relative;
  height: 7.5rem; /* needs to be same as PREVIEW_LINES */
}

.custom-fade::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.5rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

// override bootstrap 5 accordion's focus and active effects
.accordion-button:focus {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button.collapsed:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: #00000008;
}

.accordion-button {
  background-color: #00000008;
}
